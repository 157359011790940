$board-width:400px;
$cell-width:$board-width / 3;

@mixin board-hover-background-color-lighten($color) {
    background-color:$color;
    @at-root .board>a#{&}:hover {
        background-color:scale-color($color,$lightness:8%);
    }
}

.scoreboard {
    display:flex;
}

.board {
    display:grid;
    min-width:$board-width;
    min-height:$board-width;
    border:1px solid grey;
    grid-template-rows:repeat(3,1fr);
    grid-template-columns:repeat(3,1fr);

    >div,>a {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        border:1px solid grey;
        font-size:1.5rem;
    }

    >a {
        color:inherit;
        text-decoration:inherit;

        &:hover {
            background-color:rgba(white,0.08);
        }

        &.contestant1,&.contestant2,&.attempted {
            color:black;
        }
    }
}

.legend {
    margin-left:$cell-width;
    display:flex;
    flex-direction:column;
    justify-content:center;

    >div {
        display:flex;
        align-items:center;
        font-size:1.5rem;

        &:not(:last-child) {
            margin-bottom:2rem;
        }

        >.contestant1,>.contestant2 {
            width:$cell-width / 3;
            height:$cell-width / 3;
            margin-right:1rem;
        }

        >a {
            text-decoration:inherit;
        }
    }
}

.contestant1 {
    @include board-hover-background-color-lighten(#e56053);
}

.contestant2 {
    @include board-hover-background-color-lighten(#8181e3);
}

.attempted {
    @include board-hover-background-color-lighten(#e3b881);
}

.user {
    color:inherit;
}

.config>tbody>tr>td {
    padding:0.5rem;
}
